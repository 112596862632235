import { Divider, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { convertPascalCaseToWords } from "../../../../utils";
import {
  DefaultHealthRecordCardTypeProps,
  healthRecordCardLabelStyle,
} from "../HealthRecordCard";

interface AbbyDataItem {
  type: string;
  metricType: string;
  value: string;
}

interface HealthRecordCardTypeAbbyProps {
  type: string;
  name: string;
  description: string;
  dateTime: string;
  details?: {
    data?: AbbyDataItem[];
  };
}

interface Props extends DefaultHealthRecordCardTypeProps {
  data: HealthRecordCardTypeAbbyProps;
}

const unitIsShownForSuchHealthRecordItem = [
  "DiastolicBloodPressure",
  "Height",
  "SystolicBloodPressure",
  "Weight",
];

function HealthRecordCardDetailsItem({
  value,
  type,
  unit,
}: {
  value: string;
  type: string;
  unit: string;
}) {
  return (
    <Stack
      p={1}
      sx={{ background: (theme) => theme.palette.grey[100], borderRadius: 1 }}
      direction="row"
      justifyContent="space-between"
    >
      <Typography
        sx={{ ...healthRecordCardLabelStyle, fontWeight: 600 }}
        variant="body2"
      >
        {convertPascalCaseToWords(type)}{" "}
        {unitIsShownForSuchHealthRecordItem.includes(type) ? `(${unit})` : null}
      </Typography>
      <Typography
        sx={{ ...healthRecordCardLabelStyle, fontWeight: 600 }}
        variant="body2"
      >
        {value}
      </Typography>
    </Stack>
  );
}

export default function HealthRecordCardTypeAbby({
  data: { details },
  hasDetails,
}: Props) {
  const { t } = useTranslation();

  return (
    <Stack spacing={2}>
      <Divider />
      {hasDetails ? (
        <Stack spacing={2}>
          <Typography sx={healthRecordCardLabelStyle} variant="body2">
            {t("HealthRecordCardType.Default.resultLabel")}
          </Typography>
          <Stack spacing={1}>
            {details?.data &&
              details?.data.map((detailsItem: AbbyDataItem) => (
                <HealthRecordCardDetailsItem
                  type={detailsItem.type}
                  key={detailsItem.type + detailsItem.value}
                  unit={detailsItem.metricType}
                  value={detailsItem.value}
                />
              ))}
          </Stack>
        </Stack>
      ) : (
        <Typography variant="body2">
          {t("HealthRecordCardType.ABBY.noDetailsPlaceholder")}
        </Typography>
      )}
    </Stack>
  );
}
