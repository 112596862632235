import { Divider, Stack, Typography } from "@mui/material";
import { DefaultHealthRecordCardTypeProps } from "../HealthRecordCard";
import { useTranslation } from "react-i18next";

interface HealthRecordCardTypePathologyTestProps {
  type: string;
  name: string;
  description: string;
  dateTime: string;
  details?: {
    orderNumber?: string;
  };
}

interface Props extends DefaultHealthRecordCardTypeProps {
  data: HealthRecordCardTypePathologyTestProps;
}

export default function HealthRecordCardTypePathologyTest({
  data: { details },
  hasDetails,
}: Props) {
  const { t } = useTranslation();
  return (
    <Stack spacing={2}>
      <Divider />
      {hasDetails && (
        <Stack spacing={0.5}>
          <Typography variant="body2">
            {t("PathologyOrderSummary.orderNumber.title")}
          </Typography>
          <Typography variant="body2">{details?.orderNumber}</Typography>
        </Stack>
      )}
    </Stack>
  );
}
