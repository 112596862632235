import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Divider,
  Stack,
  Box,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { shadows } from "../../../../theme";
import {
  DefaultHealthRecordCardTypeProps,
  healthRecordCardLabelStyle,
} from "../HealthRecordCard";
import IconLoader from "../../../Common/components/IconLoader";

interface ProcedureItem {
  name: string;
  items: { name: string; value: string }[];
}

interface HealthRecordCardTypeMedicalDiagnosisProps {
  type: string;
  name: string;
  description: string;
  dateTime: string;
  details?: {
    procedure?: ProcedureItem[];
    practiceName?: string | null;
    treatmentLocation?: string | null;
  };
}

interface Props extends DefaultHealthRecordCardTypeProps {
  data: HealthRecordCardTypeMedicalDiagnosisProps;
}

function HealthRecordCardDetailsItem({ items, name }: ProcedureItem) {
  const hasProcedureItems = items?.length > 0;
  return (
    <Accordion
      sx={{
        p: 0,
        boxShadow: shadows.none,
        border: "none",
        ".MuiButtonBase-root": {
          minHeight: "auto",
          alignItems: "center",
        },
        ".MuiAccordionSummary-expandIconWrapper": {
          mt: 0,
          mr: 0,
        },
        ".MuiAccordionSummary-root": {
          p: 0,
        },
        ".MuiAccordionSummary-content": {
          m: 0,
          px: 0,
        },
        ".MuiAccordionDetails-root": {
          p: 0,
        },
        "&::before": {
          display: "none",
        },
      }}
      disableGutters={true}
    >
      <AccordionSummary
        expandIcon={
          hasProcedureItems ? (
            <IconLoader icon="ChevronDownIcon" />
          ) : (
            <IconLoader icon="ChevronDownIcon" sx={{ opacity: 0 }} />
          )
        }
      >
        <Typography variant="body2" sx={{ textTransform: "upperCase" }}>
          {name}
        </Typography>
      </AccordionSummary>
      {hasProcedureItems && (
        <AccordionDetails>
          <Stack spacing={1}>
            {items.map((item, index: number) => (
              <Stack
                key={item.name + String(index)}
                p={1}
                sx={{
                  background: (theme) => theme.palette.grey[100],
                  borderRadius: 1,
                }}
                direction="row"
                justifyContent="space-between"
              >
                <Typography
                  sx={{ ...healthRecordCardLabelStyle, fontWeight: 600 }}
                  variant="body2"
                >
                  {item.name}
                </Typography>
                <Typography
                  sx={{ ...healthRecordCardLabelStyle, fontWeight: 600 }}
                  variant="body2"
                >
                  {item.value}
                </Typography>
              </Stack>
            ))}
          </Stack>
        </AccordionDetails>
      )}
    </Accordion>
  );
}

export default function HealthRecordCardTypeMEDICAL_DIAGNOSIS({
  data: { details },
  hasDetails,
}: Props) {
  const { t } = useTranslation();
  return (
    <Stack spacing={2}>
      <Divider />
      {hasDetails && (
        <>
          <Stack>
            <Typography variant="body2" sx={healthRecordCardLabelStyle}>
              {t("HealthRecordCardType.MEDICAL_DIAGNOSIS.procedure")}
            </Typography>
            {details?.procedure && details.procedure.length > 0 ? (
              details?.procedure?.map((el, index: number) => (
                <HealthRecordCardDetailsItem
                  key={el.name + String(index)}
                  name={el.name}
                  items={el.items}
                />
              ))
            ) : (
              <Typography variant="body2">
                {t("HealthRecordCardType.Default.notSpecified")}
              </Typography>
            )}
          </Stack>

          <Box>
            <Typography variant="body2" sx={healthRecordCardLabelStyle}>
              {t("HealthRecordCardType.MEDICAL_DIAGNOSIS.practiceName")}
            </Typography>
            <Typography variant="body2">
              {details?.practiceName
                ? details.practiceName
                : t("HealthRecordCardType.Default.notSpecified")}
            </Typography>
          </Box>

          <Box>
            <Typography variant="body2" sx={healthRecordCardLabelStyle}>
              {t("HealthRecordCardType.MEDICAL_DIAGNOSIS.treatmentLocation")}
            </Typography>
            <Typography variant="body2">
              {details?.treatmentLocation
                ? details?.treatmentLocation
                : t("HealthRecordCardType.Default.notSpecified")}
            </Typography>
          </Box>
        </>
      )}
    </Stack>
  );
}
