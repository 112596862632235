import { Divider, Stack, Typography } from "@mui/material";
import { DefaultHealthRecordCardTypeProps } from "../HealthRecordCard";

interface HealthRecordCardTypeTelemedicineConsultProps {
  type: string;
  name: string;
  description: string;
  dateTime: string;
  details?: {
    caption?: string;
  };
}

interface Props extends DefaultHealthRecordCardTypeProps {
  data: HealthRecordCardTypeTelemedicineConsultProps;
}

export default function HealthRecordCardTypeTelemedicineConsult({
  data: { details },
  hasDetails,
}: Props) {
  return (
    <Stack spacing={2}>
      <Divider />
      {hasDetails && (
        <Typography variant="body2">{details?.caption}</Typography>
      )}
    </Stack>
  );
}
