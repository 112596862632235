import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Divider,
  Stack,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { convertPxToRem, isObjectEmpty } from "../../../../utils";
import {
  DefaultHealthRecordCardTypeProps,
  healthRecordCardLabelStyle,
} from "../HealthRecordCard";
import { HealthRecordEvent } from "../../../../services/core-api-adapter";
import IconLoader from "../../../Common/components/IconLoader";
import { shadows } from "../../../../theme";

interface HealthRecordCardTypeMedicalAssistanceProps
  extends DefaultHealthRecordCardTypeProps {
  data: HealthRecordEvent;
  hasDetails: boolean;
}

enum HealthRecordCardDetailsItemContentType {
  RECOMMENDATION = "RECOMMENDATION",
  MORE = "MORE",
  RATING = "RATING",
  POSSIBLE_CONDITIONS = "POSSIBLE_CONDITIONS",
  CONSULTATION_OUTCOME = "CONSULTATION_OUTCOME",
  PAYMENT_METHOD = "PAYMENT_METHOD",
  OUTCOME_STATUS = "OUTCOME_STATUS",
  FACE_SCAN_RESULTS = "FACE_SCAN_RESULTS",
}

interface ProcedureItem {
  details: any;
  type: HealthRecordCardDetailsItemContentType;
}

function RecommendationDetails({ items }: { items: any }) {
  return (
    <AccordionDetails>
      <Stack
        sx={{
          borderRadius: 1,
          background: (theme) => theme.palette.grey[100],
          px: 1,
          py: 2,
        }}
        spacing={1}
      >
        {items.map((item: any, index: number) => (
          <Stack
            key={item.name + String(index)}
            direction="row"
            justifyContent="space-between"
          >
            <Typography
              sx={{ ...healthRecordCardLabelStyle, fontWeight: 600 }}
              variant="body2"
            >
              {item.name}
            </Typography>
          </Stack>
        ))}
      </Stack>
    </AccordionDetails>
  );
}
function PossibleConditionsDetails({ items }: { items: any }) {
  const { t } = useTranslation();

  return (
    <AccordionDetails>
      <Stack
        sx={{
          background: (theme) => theme.palette.grey[100],
          borderRadius: 1,
          px: 1.5,
        }}
        divider={<Divider />}
      >
        {items.map((item: any, index: number) => (
          <Stack
            key={item.name + String(index)}
            direction="row"
            justifyContent="space-between"
            py={2}
          >
            <Typography
              sx={{ ...healthRecordCardLabelStyle, fontWeight: 600 }}
              variant="body2"
            >
              {item.name}
            </Typography>
            <Typography
              sx={{
                fontWeight: 600,
                color: (theme) => theme.palette.primary[700],
                textDecoration: "none",
              }}
              variant="body2"
              href={item.value}
              target="_blank"
              component="a"
            >
              {t("common.viewDetails")}
            </Typography>
          </Stack>
        ))}
      </Stack>
    </AccordionDetails>
  );
}

function MoreDetails({ items, location }: { items: any; location: string }) {
  const { t } = useTranslation();
  return (
    <AccordionDetails>
      <Stack
        sx={{
          background: (theme) => theme.palette.grey[100],
          borderRadius: 1,
          py: 1,
          px: 1.5,
        }}
        spacing={2}
      >
        {location && (
          <Stack>
            <Typography sx={{ ...healthRecordCardLabelStyle }} variant="body2">
              {t("HealthRecordCardType.Default.treatmentLocation")}
            </Typography>
            <Typography variant="body2">{location}</Typography>
          </Stack>
        )}
        <Stack spacing={2}>
          {items.map((item: any, index: number) => (
            <Stack
              key={item.name + String(index)}
              justifyContent="space-between"
            >
              <Typography
                sx={{
                  fontSize: convertPxToRem(10),
                  color: (theme) => theme.palette.grey[700],
                  fontWeight: 600,
                  mb: 1,
                }}
                variant="body2"
              >
                {item.name}
              </Typography>
              <Stack direction="row">
                <Stack sx={{ width: "50%" }} spacing={1}>
                  <Typography variant="subtitle2">
                    {t("HealthRecordCardType.Default.dateLabel")}:
                  </Typography>
                  <Typography variant="body2">
                    {new Date(item.value)?.toISOString()?.split("T")[0]}
                  </Typography>
                </Stack>
                <Stack spacing={1}>
                  <Typography variant="subtitle2">
                    {t("HealthRecordCardType.Default.timeLabel")}:
                  </Typography>
                  <Typography variant="body2">
                    {new Date(item.value).toLocaleTimeString()}
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
          ))}
        </Stack>
      </Stack>
    </AccordionDetails>
  );
}

function GeneralDetails({ items }: { items: any }) {
  return (
    <AccordionDetails>
      <Stack
        sx={{
          borderRadius: 1,
          background: (theme) => theme.palette.grey[100],
          p: 1,
        }}
        spacing={1}
      >
        {items.map((item: any, index: number) => (
          <Stack
            key={item.name + String(index)}
            direction="row"
            justifyContent="space-between"
          >
            <Typography
              sx={{ ...healthRecordCardLabelStyle, fontWeight: 600 }}
              variant="body2"
            >
              {item.name}
            </Typography>
            {item?.value && (
              <Typography
                sx={{ ...healthRecordCardLabelStyle, fontWeight: 600 }}
                variant="body2"
              >
                {item.value}
              </Typography>
            )}
          </Stack>
        ))}
      </Stack>
    </AccordionDetails>
  );
}

function FaceScanDetails({ items }: { items: any }) {
  const { t } = useTranslation();
  return (
    <AccordionDetails>
      <Stack
        sx={{
          borderRadius: 1,
          background: (theme) => theme.palette.grey[100],
          p: 1,
        }}
        spacing={1}
      >
        {items.map((item: any, index: number) => (
          <Stack
            key={item.name + String(index)}
            direction="row"
            justifyContent="space-between"
          >
            <Typography
              sx={{ ...healthRecordCardLabelStyle, fontWeight: 600 }}
              variant="body2"
            >
              {item.name}
            </Typography>
            {item?.value && (
              <Typography
                sx={{
                  fontWeight: 600,
                  color: (theme) => theme.palette.primary[700],
                  textDecoration: "none",
                }}
                variant="body2"
                href={item.value}
                target="_blank"
                component="a"
              >
                {t("common.view")}
              </Typography>
            )}
          </Stack>
        ))}
      </Stack>
    </AccordionDetails>
  );
}

function getHealthRecordCardDetailsItemContent(
  type: HealthRecordCardDetailsItemContentType,
  details: any
) {
  switch (type) {
    case HealthRecordCardDetailsItemContentType.RECOMMENDATION:
      return <RecommendationDetails items={details.items} />;

    case HealthRecordCardDetailsItemContentType.POSSIBLE_CONDITIONS:
      return <PossibleConditionsDetails items={details.items} />;

    case HealthRecordCardDetailsItemContentType.MORE:
      return <MoreDetails items={details.items} location={details.location} />;

    case HealthRecordCardDetailsItemContentType.CONSULTATION_OUTCOME:
      return <GeneralDetails items={details.items} />;

    case HealthRecordCardDetailsItemContentType.RATING:
      return <GeneralDetails items={details.items} />;

    case HealthRecordCardDetailsItemContentType.OUTCOME_STATUS:
      return <GeneralDetails items={details.items} />;

    case HealthRecordCardDetailsItemContentType.PAYMENT_METHOD:
      return <GeneralDetails items={details.items} />;

    case HealthRecordCardDetailsItemContentType.FACE_SCAN_RESULTS:
      return <FaceScanDetails items={details.items} />;

    default:
      break;
  }
}

function HealthRecordCardDetailsItem({ details, type }: ProcedureItem) {
  const hasDetails = details && !isObjectEmpty(details);
  const hasItems = details?.items?.length > 0;

  return (
    hasDetails &&
    details?.name && (
      <Accordion
        sx={{
          p: 0,
          boxShadow: shadows.none,
          border: "none",
          ".MuiButtonBase-root": {
            minHeight: "auto",
            alignItems: "center",
          },
          ".MuiAccordionSummary-expandIconWrapper": {
            mt: 0,
            mr: 0,
          },
          ".MuiAccordionSummary-root": {
            p: 0,
          },
          ".MuiAccordionSummary-content": {
            m: 0,
            px: 0,
          },
          ".MuiAccordionDetails-root": {
            p: 0,
          },
          "&::before": {
            display: "none",
          },
        }}
        disableGutters={true}
      >
        <AccordionSummary
          expandIcon={
            hasItems ? (
              <IconLoader icon="ChevronDownIcon" />
            ) : (
              <IconLoader icon="ChevronDownIcon" sx={{ opacity: 0 }} />
            )
          }
        >
          <Typography variant="body2" sx={{ textTransform: "upperCase" }}>
            {details?.name}
          </Typography>
        </AccordionSummary>
        {hasItems && getHealthRecordCardDetailsItemContent(type, details)}
      </Accordion>
    )
  );
}

export default function HealthRecordCardTypeMedicalAssistance({
  data: { details },
}: HealthRecordCardTypeMedicalAssistanceProps) {
  const { t } = useTranslation();

  return (
    <Stack>
      <Stack spacing={2}>
        <Divider />
        <Typography sx={healthRecordCardLabelStyle} variant="body2">
          {t("HealthRecordCardType.Default.resultLabel")}
        </Typography>
      </Stack>

      <HealthRecordCardDetailsItem
        details={details?.recommendation}
        type={HealthRecordCardDetailsItemContentType.RECOMMENDATION}
      />

      <HealthRecordCardDetailsItem
        details={details?.possibleConditions}
        type={HealthRecordCardDetailsItemContentType.POSSIBLE_CONDITIONS}
      />

      <HealthRecordCardDetailsItem
        details={details?.consultationOutcome}
        type={HealthRecordCardDetailsItemContentType.CONSULTATION_OUTCOME}
      />

      <HealthRecordCardDetailsItem
        details={details?.outcomeStatus}
        type={HealthRecordCardDetailsItemContentType.OUTCOME_STATUS}
      />

      <HealthRecordCardDetailsItem
        details={details?.paymentMethod}
        type={HealthRecordCardDetailsItemContentType.PAYMENT_METHOD}
      />

      <HealthRecordCardDetailsItem
        details={details?.faceScanResults}
        type={HealthRecordCardDetailsItemContentType.FACE_SCAN_RESULTS}
      />

      <HealthRecordCardDetailsItem
        details={details?.rating}
        type={HealthRecordCardDetailsItemContentType.RATING}
      />

      <HealthRecordCardDetailsItem
        details={details?.more}
        type={HealthRecordCardDetailsItemContentType.MORE}
      />
    </Stack>
  );
}
