import { Divider, Stack, Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import {
  DefaultHealthRecordCardTypeProps,
  healthRecordCardLabelStyle,
} from "../HealthRecordCard";

interface HealthRecordCardTypeMedicineDispensedProps {
  type: string;
  name: string;
  description: string;
  dateTime: string;
  details?: {
    prescription?: string;
  };
}

interface Props extends DefaultHealthRecordCardTypeProps {
  data: HealthRecordCardTypeMedicineDispensedProps;
}

export default function HealthRecordCardTypeMedicineDispensed({
  data: { details },
  hasDetails,
}: Props) {
  const { t } = useTranslation();

  return (
    <Stack spacing={2}>
      <Divider />
      {hasDetails && (
        <Box>
          <Typography sx={healthRecordCardLabelStyle} variant="body2">
            {t("HealthRecordCardType.MEDICINE_DISPENSED.prescription")}:
          </Typography>
          <Typography variant="body2">{details?.prescription}</Typography>
        </Box>
      )}
    </Stack>
  );
}
